import React from "react";
import styled from "styled-components";

const IconLeft = styled.svg`
  @media (max-width: 500px) {
    width: 47px;
    height: auto;
  }
`;

const IconRight = styled.svg`
  @media (max-width: 500px) {
    width: 60px;
    height: auto;
  }
`;

const IconMiddle = styled.svg`
  width: calc(100% - 55px - 73px - 20px);
  margin: 0 0 0 20px;

  @media (max-width: 500px) {
    width: calc(100% - 55px - 73px);
  }
`;

const Relative = styled.div`
  position: relative;
  width: 73px;
  height: 73px;

  & svg {
    position: absolute;
    top: 1px;
    right: 6px;
  }

  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
`;

export const Left = () => (
  <IconLeft
    width="55"
    height="56"
    viewBox="0 0 55 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.48 55.6499C42.6568 55.6499 54.96 43.3467 54.96 28.1699C54.96 12.9932 42.6568 0.689941 27.48 0.689941C12.3032 0.689941 0 12.9932 0 28.1699C0 43.3467 12.3032 55.6499 27.48 55.6499Z"
      fill="#fff"
    />
  </IconLeft>
);

export const Middle = () => (
  <IconMiddle
    width="1055"
    height="24"
    viewBox="0 0 1055 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      d="M0.209961 23.72V0.609985L1053.16 9.38998L1054.36 12.16L1053.16 14.94L0.209961 23.72Z"
      fill="#fff"
    />
  </IconMiddle>
);

export const Right = () => (
  <Relative>
    <IconRight
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.3202 16.66C40.9207 16.66 44.6503 12.9305 44.6503 8.33C44.6503 3.72947 40.9207 0 36.3202 0C31.7197 0 27.9902 3.72947 27.9902 8.33C27.9902 12.9305 31.7197 16.66 36.3202 16.66Z"
        fill="#fff"
      />
      <path
        d="M36.3202 72.3299C40.9207 72.3299 44.6503 68.6005 44.6503 63.9999C44.6503 59.3994 40.9207 55.6699 36.3202 55.6699C31.7197 55.6699 27.9902 59.3994 27.9902 63.9999C27.9902 68.6005 31.7197 72.3299 36.3202 72.3299Z"
        fill="#fff"
      />
      <path
        d="M0.379883 25.73L19.1799 36.17L0.379883 46.62L3.35986 36.17L0.379883 25.73Z"
        fill="#fff"
      />
      <path
        d="M64.1602 44.4901C68.7607 44.4901 72.4901 40.7606 72.4901 36.1601C72.4901 31.5595 68.7607 27.8301 64.1602 27.8301C59.5596 27.8301 55.8301 31.5595 55.8301 36.1601C55.8301 40.7606 59.5596 44.4901 64.1602 44.4901Z"
        fill="#fff"
      />
    </IconRight>
  </Relative>
);
