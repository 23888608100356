import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useSessionStorage } from "react-use";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #0e3563;

  cursor: pointer;

  opacity: ${props => props.opacity};
  transition: 250ms opacity ease;

  z-index: 1000;

  & .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    max-width: 680px;
    padding: 30px;

    z-index: 1;

    @media (max-width: 500px) {
      padding: 20px;
    }
  }

  & h1 {
    color: #fff;

    font-size: 60px;
    line-height: 66px;
    text-align: center;
    letter-spacing: 0.05em;

    @media (max-width: 800px) {
      font-size: 32px;
      line-height: 35px;
    }

    @media (max-width: 500px) {
      font-size: 30px;
      line-height: 33px;
    }

    @media (max-width: 320px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
`;

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 85vw;
  height: 75vh;

  border-radius: 100%;

  // border: 4px solid rgba(255, 255, 255, 0.3);

  @media (max-width: 768px) {
    width: 90%;
    height: 50%;
  }

  z-index: -1;

  & .circle {
    width: 30px;
    height: 30px;

    border-radius: 100%;
    background: #fff;

    z-index: 100;

    will-change: opacity;
    transition: 450ms opacity ease;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }

  .oneseven {
    // background: rgba(255, 255, 255, 0.3);
    position: absolute;

    width: 4px;
    height: 100%;

    z-index: 0;
    left: 50%;
    margin-left: -2px;
    top: 0;

    transform: rotate(30deg);

    & .circle {
      position: absolute;
      top: 0;
      left: -15px;

      opacity: ${props => props.oneOpacity};
    }
  }

  .twoeight {
    // background: rgba(255, 255, 255, 0.3);
    position: absolute;

    width: 4px;
    height: 100%;

    z-index: 0;
    left: 50%;
    margin-left: -2px;
    top: 0;

    transform: rotate(60deg);

    & .circle {
      position: absolute;
      bottom: 0;
      left: -15px;

      opacity: ${props => props.eightOpacity};

      @media (max-width: 500px) {
        left: -20px;
        bottom: -20px;
      }
    }
  }

  .fourten {
    // background: rgba(255, 255, 255, 0.3);
    position: absolute;

    width: 4px;
    height: 100%;

    z-index: 0;
    left: 50%;
    margin-left: -2px;
    top: 0;
    transform: rotate(120deg);

    & .circle {
      position: absolute;
      bottom: 0;
      left: -15px;

      opacity: ${props => props.tenOpacity};

      @media (max-width: 500px) {
        left: -20px;
        bottom: -20px;
      }
    }
  }

  .fiveeleven {
    // background: rgba(255, 255, 255, 0.3);
    position: absolute;

    width: 4px;
    height: 100%;

    z-index: 0;
    left: 50%;
    margin-left: -2px;
    top: 0;
    transform: rotate(150deg);
  }

  .twelve,
  .three,
  .six,
  .nine {
    // background: rgba(255, 255, 255, 0.3);
    position: absolute;
  }

  .twelve,
  .six {
    height: 10px;
    width: 4px;
  }

  .three,
  .nine {
    height: 4px;
    width: 10px;
  }

  .twelve {
    left: 50%;
    top: -1px;
  }

  .three {
    right: -1px;
    top: 50%;

    & .circle {
      position: absolute;
      top: 0;
      left: -15px;

      opacity: ${props => props.threeOpacity};

      @media (max-width: 500px) {
        left: 0;
      }
    }
  }

  .six {
    left: 50%;
    bottom: -1px;
  }

  .nine {
    left: -1px;
    top: 50%;
  }
`;

export const Splash = ({ splashOpacity, setSplashOpacity }) => {
  const [isSplashVisible, setIsSplashVisible] = useSessionStorage(
    "splash",
    true
  );

  // const [isSplashVisible, setIsSplashVisible] = useState(true);

  const [oneOpacity, setOneOpacity] = useState(0);
  const [threeOpacity, setThreeOpacity] = useState(0);
  const [eightOpacity, setEightOpacity] = useState(0);
  const [tenOpacity, setTenOpacity] = useState(0);

  const data = useStaticQuery(graphql`
    {
      prismicGlobalSettings {
        data {
          text {
            html
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timerOne = setTimeout(() => {
      setOneOpacity(1);
      const timerThree = setTimeout(() => {
        setOneOpacity(0);
        setThreeOpacity(1);
        const timerEight = setTimeout(() => {
          setThreeOpacity(0);
          setEightOpacity(1);
          const timerTen = setTimeout(() => {
            setEightOpacity(0);
            setTenOpacity(1);

            const splashTimer = setTimeout(() => {
              setTenOpacity(0);
              setSplashOpacity(0);
            }, 900);
            return () => clearTimeout(splashTimer);
          }, 900);
          return () => clearTimeout(timerTen);
        }, 900);
        return () => clearTimeout(timerEight);
      }, 900);
      return () => clearTimeout(timerThree);
    }, 900);
    return () => clearTimeout(timerOne);
  }, []);

  useEffect(() => {
    if (splashOpacity === 0) {
      const hideSplashTimer = setTimeout(() => {
        setIsSplashVisible(false);
      }, 500);
      return () => clearTimeout(hideSplashTimer);
    }
  }, [splashOpacity]);

  if (isSplashVisible) {
    return (
      <SplashContainer
        opacity={splashOpacity}
        onClick={() => setSplashOpacity(0)}
      >
        <div
          className="text-container transcript splash-text"
          dangerouslySetInnerHTML={{
            __html: data.prismicGlobalSettings.data.text.html,
          }}
        />

        <Circle
          threeOpacity={threeOpacity}
          oneOpacity={oneOpacity}
          eightOpacity={eightOpacity}
          tenOpacity={tenOpacity}
        >
          <div className="twelve"></div>
          <div className="three">
            <div className="circle" />
          </div>
          <div className="six"></div>
          <div className="nine"></div>

          <div className="oneseven">
            <div className="circle" />
          </div>
          <div className="twoeight">
            <div className="circle" />
          </div>
          <div className="fourten">
            <div className="circle" />
          </div>
          <div className="fiveeleven"></div>
        </Circle>
      </SplashContainer>
    );
  } else {
    return null;
  }
};
