import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export const DefaultSEO = () => {
  const data = useStaticQuery(graphql`
    {
      prismicSeo {
        data {
          seo_title
          seo_description {
            text
          }
          seo_keywords {
            text
          }
          seo_image {
            url
          }
        }
      }
    }
  `);

  return (
    <Helmet
      title={data.prismicSeo.data.seo_title}
      htmlAttributes={{ lang: "en" }}
      meta={[
        {
          name: "viewport",
          content:
            "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
        },
        {
          name: "description",
          content: `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "keywords",
          content: `${data.prismicSeo.data.seo_keywords.text}`,
        },
        {
          name: "og:image",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          name: "og:image:secure_url",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
        {
          name: "og:description",
          content: `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "og:image:width",
          content: `1200`,
        },
        {
          name: "og:image:height",
          content: `630`,
        },
        {
          name: "og:locale",
          content: `en`,
        },
        {
          name: "twitter:title",
          content: `${data.prismicSeo.data.seo_title}`,
        },
        {
          name: "twitter:description",
          content: `${data.prismicSeo.data.seo_description.text}`,
        },
        {
          name: "twitter:card",
          content: `summary_large_image`,
        },
        {
          name: "twitter:image",
          content: `${data.prismicSeo.data.seo_image.url}`,
        },
      ]}
      link={[
        {
          rel: "preload",
          href: "/fonts/fonts.css",
          as: "style",
        },
        {
          rel: "dns-prefetch",
          href: "https://images.prismic.io/hourdirectory",
        },
        {
          rel: "preconnect",
          href: "https://images.prismic.io/hourdirectory",
        },
        {
          rel: "preload",
          href: "/fonts/transcript-bold-pro.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/transcript-bold-pro.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/transcript-bold-pro.ttf",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/transcript-bold-pro.eot",
          as: "font",
          crossOrigin: "anonymous",
        },
      ]}
    >
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;1,200;1,300&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};
