import React from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";
import { Cookies } from "./cookies";

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  margin: 140px 0 0 0;
`;

const Layout = ({ children }) => (
  <Container>
    <Normalize />
    <GlobalStyles />
    <Location>
      {({ location }) => (
        <>
          <DefaultSEO />
          <App children={children} location={location} />
          <Cookies location={location.pathname} />
        </>
      )}
    </Location>
  </Container>
);

export default Layout;
