import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html,
html.wf-loading {
  main,
  .splash-text {
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-transcriptboldpro-n4-active,
html.wf-active,
html.wf-inactive {
  main,
  .splash-text {
    opacity: 1;
  }
}

body {
  font-family: "Crimson Pro", serif;
  font-weight: normal;
  font-style: normal;

  font-size: 18px;
  line-height: 24px;

  // text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  color: #0e3563;
  margin: 0;
}

*focus{
  outline: none;
}
input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-weight: normal;

  margin: 0;
}


.text-28-crimson {
  font-family: "Crimson Pro", serif;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.03em;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }
}









h1,h2,h3,h4,h5{
  font-weight: normal;
}




/* Title 1 */
// h1{
//   font-size: 50px;
//   line-height: 50px;
//   letter-spacing: 0.3px;
// }

// /* Title 2 */
// h2 {
//   font-size: 30px;
//   line-height: 32px;
//   letter-spacing: 0.2px;
// }

// /* Heading 1 */
// h3 {
//   font-size: 30px;
//   line-height: 32px;
//   letter-spacing: 0.2px;
// }

// /* Heading 2 */
// h4 {
//   font-size: 18px;
//   line-height: 24px;
//   letter-spacing: 0.2px;
// }

// /* Heading 3 */
// h5 {
//   font-size: 12px;
//   line-height: 14px;
//   letter-spacing: 0.3px;
// }


strong,
.transcript {
  font-family: "transcript-bold-pro", "Helvetica-Bold", "Lucida Grande", sans-serif;
  font-weight: normal;
}

.caps{
  text-transform: uppercase;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    color: #0e3563;
    cursor :pointer;

    line-height: 1;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

a.black-text,
.black-text {
  color: #000;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

::-webkit-input-placeholder{
    color: rgba(255, 255, 255, 0.5) !important;

    // font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
    //   sans-serif !important;
    // font-size: 24px !important;

    // @media (max-width: 500px) {
    //   font-size: 19px !important;
    // }
}

::-moz-placeholder{
  color: rgba(255, 255, 255, 0.5) !important;
    // font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
    //   sans-serif !important;
    // font-size: 24px !important;

    // @media (max-width: 500px) {
    //   font-size: 19px !important;
    // }
}

:-ms-input-placeholder{
color: rgba(255, 255, 255, 0.5) !important;
    // font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
    //   sans-serif !important;
    // font-size: 24px !important;

    // @media (max-width: 500px) {
    //   font-size: 19px !important;
    // }
}

:-moz-placeholder {
color: rgba(255, 255, 255, 0.5) !important;
    // font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
    //   sans-serif !important;
    // font-size: 24px !important;

    // @media (max-width: 500px) {
    //   font-size: 19px !important;
    // }
  }


  /*--------------------------------------------------------------
  ## Cookie Consent
  --------------------------------------------------------------*/

.cookie {
  bottom: 20px !important;
  padding: 15px;
}

  .cookieConsent a{
    text-decoration: underline;
  }

  .cookie-button{
    cursor: pointer;
  }

  .cookie-button:focus{
    outline: none;
  }
`;

export default GlobalStyle;
