import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Components
import { LinkIcon } from "../icons/link-arrow";

const buttonColor = keyframes`
0%{
  color: #0e3563;
}

10%{
  color: #081D36;
}

20%{
  color: #000000;
}

30%{
 color:  #081D36;
}


50%{
  color: #0e3563;
}

70%{
  color: #174984;
}

80%{
  color: #1E62B3;
}

90%{
  color: #174984;
}

100%{
  color: #0e3563;
}
`;

const Form = styled.form`
  margin: 30px 0;
  border: 2px solid #ffffff;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 18px 20px;

  & input {
    border: 0;
    padding: 0;

    max-width: calc(100% - 35px);
    width: 100%;

    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;

    color: #fff;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
    }
  }

  & button {
    border: 0;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const FormSubmitted = styled.div`
  margin: 30px 0;
  border: 2px solid #fff;
  background-color: #fff;

  padding: 18px 20px;

  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #0e3563;

  & p {
    margin: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 21px;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ErrorMessage = styled.p`
  margin: 1em 0 0 0;
`;

export const SignupForm = () => {
  const [email, setEmail] = useState("");
  const [formMessage, setFormMessage] = useState(null);
  const [mailchimpMessage, setMailchimpMessage] = useState(null);

  // 1. via `.then`
  const handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg === "The email you entered is not valid.") {
            setFormMessage("Invalid Email");
          }

          if (data.msg.includes("already subscribed")) {
            setFormMessage(`Email is already subscribed`);
          }
        } else {
          setMailchimpMessage("Done!");
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (mailchimpMessage !== null) {
      const resetFormVisbility = setTimeout(() => {
        setMailchimpMessage(null);
        setFormMessage(null);
        setEmail("");
      }, 5000);
      return () => clearTimeout(resetFormVisbility);
    }
  }, [mailchimpMessage]);

  return (
    <>
      {mailchimpMessage === null ? (
        <>
          <Form onSubmit={e => handleSubmit(e)}>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              name="EMAIL"
              className="transcript"
              id="mce-EMAIL"
              placeholder="Subscribe for updates"
            />
            <button type="submit">
              <LinkIcon
                fill={email === "" ? `rgba(255, 255, 255, 0.5)` : `#FFFFFF`}
              />
            </button>
          </Form>
          {formMessage !== null && <ErrorMessage>{formMessage}</ErrorMessage>}
        </>
      ) : (
        <FormSubmitted>
          <p className="transcript">{mailchimpMessage}</p>
        </FormSubmitted>
      )}
    </>
  );
};
