import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  & path {
    fill: ${props => props.fill};
  }
`;

export const LinkIcon = ({ fill }) => (
  <Icon
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="M0.0944824 0L18.0472 10.495L0.0944824 21L2.94016 10.495L0.0944824 0Z" />
  </Icon>
);

const RotatedIcon = styled.svg`
  transform: rotate(${props => props.rotation}deg);

  width: 10px;
  height: auto;

  & path {
    fill: #0e3563;
  }
`;

export const DownArrowIcon = ({ rotation }) => (
  <RotatedIcon
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    rotation={rotation}
  >
    <path d="M0.0944824 0L18.0472 10.495L0.0944824 21L2.94016 10.495L0.0944824 0Z" />
  </RotatedIcon>
);
