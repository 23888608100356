import React, { useState, useEffect, useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";

// Context
import { PageTypeContext } from "../context/page-type";

// Components
import { Left, Middle, Right } from "../icons/footer-icons";
import { SignupForm } from "../forms/form";

const backgroundColor = keyframes`
0%{
  background-color: #0e3563;
}

10%{
  background-color: #081D36;
}

20%{
  background-color: #000000;
}

30%{
 background-color:  #081D36;
}


50%{
  background-color: #0e3563;
}

70%{
  background-color: #174984;
}

80%{
  background-color: #1E62B3;
}

90%{
  background-color: #174984;
}

100%{
  background-color: #0e3563;
}
`;

const animation = props =>
  css`
    ${backgroundColor} 30s linear infinite;
  `;

const FooterWrapper = styled.footer`
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  animation: ${props => (props.playAnimation ? animation : `none`)};
  background-color: ${props => (props.playAnimation ? `none` : `#0e3563`)};
  color: #fff;

  & .icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }

  padding: 45px 90px;

  @media (max-width: 1200px) {
    padding: 30px 60px;
  }

  @media (max-width: 1000px) {
    padding: 30px 40px;
  }

  @media (max-width: 768px) {
    padding: 30px 20px 20px 20px;
  }
`;

const ContentContainer = styled.div`
  // max-width: 1260px;
  width: 100%;

  // margin: 0 auto;
`;

const Credits = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 60px 0 0 0;

  color: #fff;

  & p {
    margin: 0;

    &:nth-of-type(2) {
      margin: 0 15px;
    }

    & a {
      text-decoration: none;
      border-bottom: 1px solid rgb(255 255 255 / 30%);
      transition: 250ms border-bottom ease;

      &:hover {
        border-bottom: 1px solid rgb(255 255 255);
      }
    }
  }

  @media (max-width: 768px) {
    padding: 40px 0 0 0;

    & p {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px 1fr 60px 1fr;

  margin: 0 0 150px 0;

  & .small-text p {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
  }

  & .medium-text {
    max-width: 320px;

    & > p {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.03em;

      @media (max-width: 768px) {
        font-size: 21px;
        line-height: 24px;
        letter-spacing: 0.02em;

        & strong {
          font-size: 20px;
          line-height: 25px;
          letter-spacing: 0.05em;
        }
      }
    }

    @media (max-width: 768px) {
      max-width: 290px;

      padding: 0 0 30px 0;
    }
  }

  & a {
    text-decoration: none;
    border-bottom: 1px solid rgb(255 255 255 / 30%);
    transition: 250ms border-bottom ease;

    &:hover {
      border-bottom: 1px solid rgb(255 255 255);
    }
  }

  & .text-container {
    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }
  }

  & .spacer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 768px) {
      display: block;

      padding: 20px 0;
    }

    & .line {
      background-image: linear-gradient(white 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 3px;
      background-repeat: repeat-y;

      height: calc(100% + 60px);
      width: 1px;

      @media (max-width: 768px) {
        height: 1px;
        width: 100%;

        background-image: linear-gradient(
          to right,
          #fff 33%,
          rgba(14, 53, 99, 0) 0%
        );

        background-position: bottom;
        background-size: 3px 1px;
        background-repeat: repeat-x;
      }
    }
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 40px 1fr 40px 1fr;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Footer = () => {
  const [pageTypeContext, setPageTypeContext] = useContext(PageTypeContext);

  const copyrightYear = new Date();
  const formattedYear = copyrightYear.getFullYear();

  const data = useStaticQuery(graphql`
    {
      prismicGlobalSettings {
        data {
          text_column_left {
            html
          }
          text_column_middle {
            html
          }
          text_column_right {
            html
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper playAnimation={pageTypeContext === `about` ? false : true}>
      <ContentContainer>
        {pageTypeContext !== `about` && (
          <Grid>
            <div className="text-container">
              <div
                className="medium-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicGlobalSettings.data.text_column_left.html,
                }}
              />
            </div>
            <div className="spacer">
              <div className="line" />
            </div>
            <div
              className="small-text text-container"
              dangerouslySetInnerHTML={{
                __html: data.prismicGlobalSettings.data.text_column_middle.html,
              }}
            />
            <div className="spacer">
              <div className="line" />
            </div>

            <div>
              <div
                className="small-text text-container"
                dangerouslySetInnerHTML={{
                  __html:
                    data.prismicGlobalSettings.data.text_column_right.html,
                }}
              />

              <SignupForm />
            </div>
          </Grid>
        )}

        <div className="icons">
          <Left />
          <Middle />
          <Right />
        </div>

        <Credits>
          <p className="copyright">© {formattedYear}</p>
          <p>
            Website by{" "}
            <a href="https://covers-studio.com/" target="_blank">
              Covers
            </a>
          </p>
          <p>
            <Link to={`/privacy-policy`}>Privacy policy</Link>
          </p>
        </Credits>
      </ContentContainer>
    </FooterWrapper>
  );
};
