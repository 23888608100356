import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

export const Cookies = ({ location }) => (
  <CookieConsent
    location="bottom"
    buttonText="Accept and Close"
    cookieName="hour-directory-gdpr-google-analytics"
    containerClasses="cookie"
    buttonClasses="cookie-button"
    disableStyles={true}
    style={{
      alignItems: "baseline",
      background: "rgba(255, 255, 255, 1)",
      color: "#404040",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      left: "unset",
      right: "20px",
      bottom: "20px",
      position: "fixed",
      width: "100%",
      maxWidth: "280px",
      zIndex: "999",
      fontSize: "15px",
      borderRadius: "3px",
      boxShadow:
        "0 5px 15px 0 rgba(90,50,40,0.15), 0 4px 8px 0 rgba(80,40,30,0.1)",
    }}
    buttonStyle={{
      color: "#404040",
      fontSize: "15px",
      backgroundColor: "#fff",
      border: "1px solid #818181",
      margin: "15px 0 0 0",
      padding: "7px 10px",
    }}
    expires={150}
    onAccept={() => {
      initializeAndTrack(location);
    }}
  >
    This website uses cookies to enhance the user experience. Learn more about
    how we use cookies by reviewing our{" "}
    <Link to={"/privacy-policy"}>privacy policy</Link>.
  </CookieConsent>
);
