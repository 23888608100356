import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
  }
`;

export const AboutIcon = () => (
  <Icon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#0E3563" fillOpacity="0.1" />
    <path
      d="M17.1526 19.84C18.582 19.5627 19.6913 19.0507 20.4806 18.304C21.2913 17.536 21.6966 16.512 21.6966 15.232C21.6966 14.4 21.5473 13.7813 21.2486 13.376C20.9713 12.9707 20.566 12.768 20.0326 12.768C19.4566 12.768 19.0086 13.024 18.6886 13.536C18.39 14.0267 18.2406 14.592 18.2406 15.232H13.7606C13.7606 14.1867 14.006 13.216 14.4966 12.32C15.0086 11.4027 15.734 10.6667 16.6726 10.112C17.6326 9.55733 18.7526 9.28 20.0326 9.28C22.0806 9.28 23.6806 9.81333 24.8326 10.88C25.9846 11.9253 26.5606 13.376 26.5606 15.232C26.5606 16.7467 26.1446 17.9947 25.3126 18.976C24.4806 19.936 23.382 20.7467 22.0166 21.408L21.0566 24.832H18.1126L17.1526 19.84ZM17.1526 27.136H22.0166V32H17.1526V27.136Z"
      fill="#0E3563"
    />
  </Icon>
);
