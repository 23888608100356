import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import Select from "react-select";
import { useWindowScroll } from "react-use";

// Context
import { ActiveListingCountry } from "../context/active-country";
import { ActiveListingCity } from "../context/active-city";
import { PageTitleContext } from "../context/page-title";
import { PageTypeContext } from "../context/page-type";

// Components
import { LocationDropdowns } from "../navigation/location-dropdowns";

// Icons
import { AboutIcon } from "../icons/about";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  transition: transform 250ms ease;
  transform: translateY(${props => props.translateY});

  padding: 0 90px;

  background-color: #fff;
  z-index: 200;

  & .left-menu-item {
    flex: 1 1 0%;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    margin-right: auto;
  }

  & .center-menu-item {
    flex: 1 1 0%;

    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px auto;
  }

  & .right-menu-item {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-left: auto;
  }

  @media (max-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;

    line-height: 35px;
  }
`;

const Selector = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  font-family: "transcript-bold-pro", "Helvetica-Bold", "Lucida Grande",
    sans-serif;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.05em;

  & p,
  & span {
    margin: 0 9px 0 0;
  }

  & .left-column,
  & .selectors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    & .country-selector {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;
    }
  }

  @media (max-width: 1000px) {
    font-size: 25px;
    line-height: 30px;
  }

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 1;

    & .left-column {
      width: 100%;
    }
  }
`;

const Gradient = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;

  height: 10px;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  mix-blend-mode: multiply;
`;

const DirectoryLink = styled.div`
  & p {
    font-family: "transcript-bold-pro", "Helvetica-Bold", "Lucida Grande",
      sans-serif;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.05em;

    margin: 0;

    @media (max-width: 1000px) {
      font-size: 25px;
      line-height: 30px;
    }

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
    }
  }

  & a {
    text-decoration: none;
    border-bottom: 2px solid rgb(14 53 99);
  }

  @media (max-width: 768px) {
    position: absolute;
    top: 15px;
    left: 15px;
  }
`;

const PageTitle = styled.div`
  & h1 {
    font-family: "transcript-bold-pro", "Helvetica-Bold", "Lucida Grande",
      sans-serif;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.05em;

    @media (max-width: 1000px) {
      font-size: 25px;
      line-height: 30px;
    }
  }

  @media (max-width: 768px) {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;

    text-align: center;
  }
`;

const AboutLink = styled.div`
  flex: ${props => props.flex};

  @media (max-width: 768px) {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

export const Header = ({ location }) => {
  const { x, y } = useWindowScroll();

  const [pageTitleContext, setPageTitleContext] = useContext(PageTitleContext);
  const [pageTypeContext, setPageTypeContext] = useContext(PageTypeContext);
  const [activeCountry, setActiveCountry] = useContext(ActiveListingCountry);
  const [activeCity, setActiveCity] = useContext(ActiveListingCity);

  const [selectedCountry, setSelectedCountry] = useState("all locations");
  const [selectedCity, setSelectedCity] = useState("all areas");
  const [cityOptions, setCityOptions] = useState(null);

  const data = useStaticQuery(graphql`
    {
      allPrismicCountry(sort: { fields: data___name___text, order: ASC }) {
        edges {
          node {
            id
            url
            data {
              name {
                text
              }
              cities {
                city {
                  document {
                    ... on PrismicCity {
                      id
                      url
                      data {
                        name {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // Header scroll
  const [lastScroll, setLastScroll] = useState(0);
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  useEffect(() => {
    if (y <= 0) {
      setIsMenuVisible(true);
      return;
    }

    if (y > lastScroll && y > 140 && isMenuVisible) {
      // down
      setIsMenuVisible(false);
    } else if (y < lastScroll && !isMenuVisible) {
      // up
      setIsMenuVisible(true);
    }
    setLastScroll(y);
  }, [y, lastScroll, setLastScroll, setIsMenuVisible]);

  const countryOptions = data.allPrismicCountry.edges.map((country, index) => {
    return {
      id: country.node.id,
      url: country.node.url,
      label: country.node.data.name.text,
      cities: country.node.data.cities.filter(
        city => city.city.document !== null
      ),
    };
  });

  return (
    <HeaderWrapper translateY={isMenuVisible ? `0` : `-100%`}>
      {(pageTypeContext === `about` || pageTypeContext === `privacy`) && (
        <DirectoryLink className="left-menu-item">
          <p>
            Explore <Link to={`/`}>directory</Link>
          </p>
        </DirectoryLink>
      )}

      {pageTypeContext === `listing` && (
        <DirectoryLink className="left-menu-item">
          <p>
            Explore <Link to={activeCountry.url}>{activeCountry.name}</Link>
          </p>
        </DirectoryLink>
      )}

      {pageTypeContext !== `listing` &&
        pageTypeContext !== `about` &&
        pageTypeContext !== `privacy` && (
          <Selector className="left-menu-item">
            <LocationDropdowns
              options={countryOptions}
              activeCountry={activeCountry}
              activeCity={activeCity}
              location={location}
            />
          </Selector>
        )}

      {(pageTypeContext === `listing` ||
        pageTypeContext === `about` ||
        pageTypeContext === `privacy`) && (
        <PageTitle className="center-menu-item">
          <h1>{pageTitleContext}</h1>
        </PageTitle>
      )}

      <AboutLink
        className="right-menu-item"
        flex={
          pageTypeContext === `listing` ||
          pageTypeContext === `about` ||
          pageTypeContext === `privacy`
            ? `1 1 0%`
            : `0`
        }
      >
        <Link to={`/about`}>
          <AboutIcon />
        </Link>
      </AboutLink>

      <Gradient />
    </HeaderWrapper>
  );
};
