import React, { useState } from "react";
import styled from "styled-components";

// Context
import { ActiveListingCity } from "../context/active-city";
import { ActiveListingCountry } from "../context/active-country";
import { PageTitleContext } from "../context/page-title";
import { PageTypeContext } from "../context/page-type";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { Splash } from "../splash/splash";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ children, location }) => {
  const [pageTitleContext, setPageTitleContext] = useState("");
  const [pageTypeContext, setPageTypeContext] = useState("");
  const [activeCountry, setActiveCountry] = useState(null);
  const [activeCity, setActiveCity] = useState(null);
  const [splashOpacity, setSplashOpacity] = useState(1);

  return (
    <PageTypeContext.Provider value={[pageTypeContext, setPageTypeContext]}>
      <PageTitleContext.Provider
        value={[pageTitleContext, setPageTitleContext]}
      >
        <ActiveListingCountry.Provider
          value={[activeCountry, setActiveCountry]}
        >
          <ActiveListingCity.Provider value={[activeCity, setActiveCity]}>
            <Splash
              splashOpacity={splashOpacity}
              setSplashOpacity={setSplashOpacity}
            />
            <Header location={location} />
            <Main>{children}</Main>
            <Footer />
          </ActiveListingCity.Provider>
        </ActiveListingCountry.Provider>
      </PageTitleContext.Provider>
    </PageTypeContext.Provider>
  );
};
