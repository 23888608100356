import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Utils
import { useDetectOutsideClick } from "../utils/useDetectOutsideClick";

const DropdownSelector = styled.div`
  position: relative;
  width: auto;

  & button {
    border-radius: 0;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 14px 17px 9px 17px;
    vertical-align: middle;

    border: 0;
    background-color: rgba(14, 53, 99, 0.1);
    transition: 250ms background-color ease;

    letter-spacing: 0.05em;

    &.open,
    &:hover {
      background-color: rgba(14, 53, 99, 0.3);
    }

    @media (max-width: 768px) {
      padding: 8px 9px 6px 9px;
    }
  }

  & nav {
    background: #e7ebef;
    position: absolute;

    top: 55px;
    left: 0;

    width: auto;

    z-index: 10;
    display: none;

    &.active {
      display: block;
    }

    & li {
      &.current {
        background-color: rgba(14, 53, 99, 0.3);
      }

      &:hover {
        background-color: #0e3563;
        color: #fff;
      }

      & a {
        text-decoration: none;

        padding: 10px 17px 5px 17px;
        display: block;
        letter-spacing: 0.05em;

        width: max-content;

        @media (max-width: 768px) {
          padding: 8px 9px 6px 9px;
        }
      }
    }

    @media (max-width: 768px) {
      top: 36px;
    }
  }

  @media (max-width: 768px) {
    &.city-selector {
      margin: 5px 0 0 0;
    }
  }
`;

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-dropdown-menu-component-with-react-hooks
 */
export const LocationDropdowns = ({
  options,
  activeCountry,
  activeCity,
  location,
}) => {
  // Country
  const countryDropdownRef = useRef(null);
  const [isCountryActive, setIsCountryActive] = useDetectOutsideClick(
    countryDropdownRef,
    false
  );

  // City
  const cityDropdownRef = useRef(null);
  const [isCityActive, setIsCityActive] = useDetectOutsideClick(
    cityDropdownRef,
    false
  );

  useEffect(() => {
    setIsCountryActive(false);
    setIsCityActive(false);
  }, [location]);

  const countryDropdownOptions = options.map(country => (
    <li
      key={`single_country_dropdown_${country.id}`}
      className={
        activeCountry !== null
          ? activeCountry.name === country.label
            ? `current`
            : ``
          : ``
      }
    >
      <Link to={country.url}>{country.label}</Link>
    </li>
  ));

  const cityDropdownOptions =
    activeCountry !== null
      ? options
          .filter(country => country.label === activeCountry.name)
          .map(country =>
            country.cities.map(city => (
              <li
                key={`single_city_dropdown_${country.id}_${city.city.document.id}`}
                className={
                  activeCity !== null
                    ? activeCity.name === city.city.document.data.name.text
                      ? `current`
                      : ``
                    : ``
                }
              >
                <Link to={city.city.document.url}>
                  near {city.city.document.data.name.text}
                </Link>
              </li>
            ))
          )
      : null;

  return (
    <div className="selectors">
      <div className="left-column">
        <p>Places in</p>

        <DropdownSelector className="country-selector">
          <button
            onClick={() => setIsCountryActive(!isCountryActive)}
            className={`button ${isCountryActive ? "open" : ``}`}
          >
            {activeCountry !== null ? activeCountry.name : <>all locations</>}
          </button>

          <nav
            ref={countryDropdownRef}
            className={`menu ${isCountryActive ? "active" : "inactive"}`}
          >
            <ul>
              <li className={activeCountry === null ? `current` : ``}>
                <Link to={`/`}>all locations</Link>
              </li>
              {countryDropdownOptions}
            </ul>
          </nav>
          {activeCountry !== null && <p>,</p>}
        </DropdownSelector>
      </div>

      {activeCountry !== null && (
        <DropdownSelector className="city-selector">
          <button
            onClick={() => setIsCityActive(!isCityActive)}
            className={`button ${isCityActive ? "open" : ``}`}
          >
            {activeCity !== null ? `near ${activeCity.name}` : <>all areas</>}
          </button>

          <nav
            ref={cityDropdownRef}
            className={`menu ${isCityActive ? "active" : "inactive"}`}
          >
            <ul>
              <li className={activeCity === null ? `current` : ``}>
                <Link to={activeCountry.url}>all areas</Link>
              </li>
              {cityDropdownOptions}
            </ul>
          </nav>
        </DropdownSelector>
      )}
    </div>
  );
};
